import React from 'react'

import { Helmet } from 'react-helmet'

import './home-wip.css'

const HomeWIP = (props) => {
  return (
    <div className="home-wip-container">
      <Helmet>
        <title>Spectrem</title>
        <meta property="og:title" content="Spectrem" />
      </Helmet>
      <div className="home-wip-hero">
        <div className="home-wip-bg"></div>
        <div className="home-wip-container1">
          <video
            src="bg-video.mp4"
            muted
            loop
            autoPlay
            playsInline
            className="home-wip-video"
          ></video>
          <img
            src="spectrem-logo.png"
            alt="image"
            className="home-wip-image"
          />
          <img
            src="spectrem-text-logo.png"
            alt="image"
            className="home-wip-text-image"
          />
        </div>
      </div>
    </div>
  )
}

export default HomeWIP
